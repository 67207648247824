import { isAdmin, isLandlord, roles } from '_graphql/_enums'
import {INVOICE_DELETE_MUTATION,  INVOICE_LIST, INVOICE_UPSERT_MUTATION } from '_graphql/invoice'
import {
  CloudServerOutlined,
  CopyOutlined,
  CrownOutlined,
  DeleteOutlined,
  FolderViewOutlined,
  PlusOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  UserAddOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons'
import { useMutation } from '@apollo/react-hooks'
import { Launch } from '@material-ui/icons'
import { Link,navigate } from '@reach/router'
import { Button, message, Modal,Popconfirm, Tag,Tooltip } from 'antd'
import { useModals } from 'common/ModalsProvider'
import { ListManager } from 'components/ListManager'
import { cost } from 'components/ListManager/DefaultTypes'
import { string } from 'components/ListManager/DefaultTypes'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'

import { InvoicePage } from './InvoicePage'

const InvoicesManager = (props) => {
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [onSave] = useState(false)
  const isManagementUser = isAdmin(state.currentUser?.role)
  const { openModal } = useModals()

  const [deleteInvoice] = useMutation(INVOICE_DELETE_MUTATION)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    onSave(true)
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const archiveInvoiceAction = async (id) => {
    console.log('del invoice',id)
    const response = await deleteInvoice({
      variables: {
        id
      },
    })

    if (!response?.errors) {
      message.success(t('transactions.form.deleteSuccess'))
    } else { 
      message.warning(t('transactions.form.deleteError'))
      
    }
  }

  return (
    <>
      <ListManager
        slug="bills-manager"
        title={t('invoices.table.title')}
        query={INVOICE_LIST}
        actions={
          isManagementUser
            ? [
              <Link 
                to="/invoices-management/create" 
                key="create"
                onClick={(e) => {
                  e.preventDefault()
                  openModal(InvoicePage, {
                    onClose(sku) {
                      navigate(`/invoices-management/${sku || ''}`)
                    }
                  })
                }}
              >
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  htmlType="span"
                >
                  {t('invoices.table.create')}
                </Button>
              </Link>,
            ]
            : []
        }
        sorting={{
          default: 'id|desc',
          variations: [
            {
              slug: 'id|desc',
              title: t('invoices.table.sorting.idDesc'),
              icon: <SortDescendingOutlined />,
            },
            {
              slug: 'id|asc',
              title: t('invoices.table.sorting.idAsc'),
              icon: <SortAscendingOutlined />,
            },
          ],
        }}
        filter={{
          defaultPreset: 'all',
          presets: [
            {
              title: t('invoices.table.filters.all'),
              slug: 'all',
              value: {},
            },
          ],
          filters: [],
        }}
        table={{
          columns: [
            {
              title: t('invoices.table.sku'),
              key: 'sku',
              width: 50,
              options: { na: true },
              render: ({ value, options, item }) => (
                <Link to={`/invoices-management/${item?.sku}`}>
                  <Launch fontSize="small" className="icon--mr" />
                </Link>
                 
              ),
            },
            {
              title: t('invoices.table.RCID'),
              key: 'rc',
              width: 100,
              options: { na: true },
              render: ({ value, options, item }) => (
               
                <Link to={`/rental-contracts-management/${item.rentalContract?.sku}`}>
                  { item?.rentalContract?.sku.substr(0, 8) }
                </Link>
              ),
            },
            // {
            //   title: t('invoices.table.reference'),
            //   key: 'reference',
            //   width: 100,
            //   render: 'string',
            // },
            {
              title: t('invoices.table.iban'),
              key: 'iban',
              width: 100,
              render: 'string',
            },
            {
              title: t('invoices.table.bicCode'),
              key: 'bicCode',
              width: 100,
              render: 'string',
            },
            {
              title: t('invoices.table.recipient'),
              key: 'recipient',
              width: 80,
              render: 'string',
            },
            {
              title: t('invoices.table.grossPrice'),
              key: 'grossPrice',
              render: ({ item }) => {
               
                return (
                  <Tooltip
                    placement="top"
                    title={(
                      <>
                        <strong>{t('invoices.table.grossPrice')}:</strong> <span>{cost({ value: item.grossPrice })}</span><br />
                        <strong>{t('invoices.table.netPrice')}:</strong> <span>{cost({ value: item.netPrice })}</span><br />
                        <strong>{t('invoices.table.vat')}:</strong> <span>{item.vat*100}%</span>
                      </>
                    )}
                  >
                    {cost({ value: item.grossPrice })}
                  </Tooltip>
                )
              },
            },
            {
              title: t('invoices.table.paymentDate'),
              key: 'paymentDate',
              render: ({ item }) => { 
                
                const activeFrom = item?.paymentDate
                  ? DateTime.fromISO(item?.paymentDate)
                  : null
                
                return activeFrom ? activeFrom?.toFormat('yyyy LLL dd') : ''
              },
            },
            {
              title: t('invoices.table.invoiceDate'),
              key: 'invoiceDate',
              render: ({ item }) => { 
                
                const activeFrom = item?.invoiceDate
                  ? DateTime.fromISO(item?.invoiceDate)
                  : null
                
                return activeFrom ? activeFrom?.toFormat('yyyy LLL dd') : ''
              },
            },
            {
              title: t('invoices.table.bookingDate'),
              key: 'bookingDate',
              render: ({ item }) => { 
                
                const activeFrom = item?.bookingDate
                  ? DateTime.fromISO(item?.bookingDate)
                  : null
                
                return activeFrom ? activeFrom?.toFormat('yyyy LLL dd') : ''
              },
            },
            {
              title: t('invoices.table.method.title'),
              key: 'status',
              options: { na: true },
              render: ({ value, options, item }) => (<Tag>{ item.method != 1 ? t('invoices.table.method.recurring') : t('invoices.table.method.bill') }</Tag>),
            },
            {
              key: 'actions',
              title: t('invoices.table.actions.title'),
              width: 100,
              render: ({ item, context }) => (
                <>
                
                  <Tooltip
                    placement="bottom"
                    title={t('invoices.table.actions.archive')}
                  >
                    <Popconfirm
                      title={t('invoices.table.actions.archiveConfirm')}
                      onConfirm={() =>
                        archiveInvoiceAction(item?.id)
                      }
                    >
                      <Button
                        shape="circle"
                        type="link"
                        icon={<CloudServerOutlined />}
                      />
                    </Popconfirm>
                  </Tooltip>
                </>
              ),
            },
          ],
        }}
        {...props}
      />
      <Modal
        width={1000}
        title="Basic Modal"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <InvoicePage onSave={onSave} />
      </Modal>
    </>
  )
}

export { InvoicePage } from './InvoicePage'
export { InvoicesManager }
