import { gql } from 'apollo-boost'

import { projectFragment, userFragment, wbsFragment } from '../_fragments'

export const WBS_LIST = gql`
  query listWbs(
    $page: Int!
    $pageSize: Int!
    $sorting: String
    $search: String
    $filter: JSON
  ) {
    response: listWbs(
      page: $page
      pageSize: $pageSize
      sorting: $sorting
      search: $search
      filter: $filter
    ) {
      list {
        ...wbsFragment
      }
      pagination {
        page
        pageSize
        total
      }
    }
  }
  ${wbsFragment}
`

export const EMAILS_LIST = gql`
  query listEmailRequest(
    $page: Int!
    $pageSize: Int!
    $sorting: String
    $search: String
    $filter: JSON
  ) {
    response: listEmailRequest(
      page: $page
      pageSize: $pageSize
      sorting: $sorting
      search: $search
      filter: $filter
    ) {
      list {
        id
        sku
        location
        name
        email
        phone
        website
        createdAt
      }
      pagination {
        page
        pageSize
        total
      }
    }
  }
`

export const GET_WBS = gql`
  query findWbs($id: Int!) {
    response: findWbs(id: $id) {
      ...wbsFragment
      project {
        ...projectFragment
      }
    }
  }
  ${wbsFragment}
  ${projectFragment}
`

export const GET_WBS_BY_SKU = gql`
  query findWbsBySku($sku: String!) {
    response: findWbsBySku(sku: $sku) {
      ...wbsFragment
      project {
        ...projectFragment
      }
      customer {
        ...userFragment
      }
      pmo {
        ...userFragment
      }
    }
  }
  ${wbsFragment}
  ${projectFragment}
  ${userFragment}
`

export const SUBSCRIBE_WBS = gql`
  subscription wbsChanged($id: Int!) {
    response: wbsChanged(id: $id) {
      ...wbsFragment
      project {
        ...projectFragment
      }
    }
  }
  ${wbsFragment}
  ${projectFragment}
`
