import { isLandlord, roles } from '_graphql/_enums'
import { EMAILS_LIST } from '_graphql/wbs'
import EyeOutline from '@2fd/ant-design-icons/lib/EyeOutline'
import {
  CloudServerOutlined,
  CopyOutlined,
  CrownOutlined,
  DeleteOutlined,
  FolderViewOutlined,
  PlusOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  UserAddOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons'
import { useMutation } from '@apollo/react-hooks'
import { Link, navigate } from '@reach/router'
import { Button, Popconfirm, Tooltip } from 'antd'
import { useModals } from 'common/ModalsProvider'
import { ListManager } from 'components/ListManager'
import { string } from 'components/ListManager/DefaultTypes'
import { DateTime } from 'luxon'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'
import { CountryRender } from 'utils/countries'
import { deepOmit } from 'utils/deepOmit'

const EmailsManager = (props) => {
  const { t } = useTranslation()

  return (
    <>
      <ListManager
        slug="wbs-manager"
        title="Email Requests Manager"
        query={EMAILS_LIST}
        actions={[]}
        sorting={{
          default: 'id|desc',
          variations: [
            {
              slug: 'id|desc',
              title: t('wbs.table.sorting.idDesc'),
              icon: <SortDescendingOutlined />,
            },
            {
              slug: 'id|asc',
              title: t('wbs.table.sorting.idAsc'),
              icon: <SortAscendingOutlined />,
            },
          ],
        }}
        filter={{
          defaultPreset: 'all',
          presets: [
            {
              title: t('wbs.table.filters.all'),
              slug: 'all',
              value: {},
            },
          ],
          filters: [],
        }}
        table={{
          columns: [
            {
              title: t('wbs.table.sku'),
              key: 'sku',
              options: { na: true },
              render: ({ options, item }) => (
                <div style={{ display: 'flex' }}>
                  {item?.sku ? item.sku.slice(0, 8) : item.id}
                </div>
              ),
            },
            {
              title: 'Location',
              key: 'location',
              options: { na: true },
              render: 'string',
            },
            {
              title: 'Name',
              key: 'name',
              options: { na: true },
              render: 'string',
            },
            {
              title: 'Phone',
              key: 'phone',
              options: { na: true },
              render: 'string',
            },
            {
              title: 'Email',
              key: 'email',
              options: { na: true },
              render: 'string',
            },
            {
              title: 'Website',
              key: 'website',
              options: { na: true },
              render: 'string',
            },
            {
              title: t('wbs.table.createdAt'),
              key: 'createdAt',
              render: ({ item }) => {
                const createdAt = item?.createdAt
                  ? DateTime.fromISO(item?.createdAt)
                  : null

                return createdAt ? createdAt?.toFormat('yyyy-LL-dd') : ''
              },
            },
          ],
        }}
        {...props}
      />
    </>
  )
}

export { EmailsManager }
