import { isAdmin, isLandlord,isTenant, RENTAL_REQUESTS_STATUSES, REQUESTED_APARTMENT_STATUSES, roles} from '_graphql/_enums'
import EyeOutline from '@2fd/ant-design-icons/lib/EyeOutline'
import { CopyOutlined, DeleteOutlined, SettingOutlined } from '@ant-design/icons'
import Bathtub from '@material-ui/icons/Bathtub'
import KingBed from '@material-ui/icons/KingBed'
import LocationOn from '@material-ui/icons/LocationOn'
import { Link } from '@reach/router'
import { Alert, Button, Popconfirm, Tag, Tooltip, Typography } from 'antd'
import { useModals } from 'common/ModalsProvider'
import { cost } from 'components/ListManager/DefaultTypes'
import { user as userRender } from 'components/ListManager/DefaultTypes'
import APT_PLACEHOLDER from 'img/apt_placeholder.png'
import head from 'lodash/head'
import size from 'lodash/size'
import { DateTime } from 'luxon'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'

import { BuildingDetails } from '../../BuildingsManager/BuildingDetails'
import { ApartmentDetails } from '../ApatmentDetails'


/**
 * Апартаменты : Плитка
 */
export const ApartmentTile = ({ item, actions, onMouseEnter, onMouseLeave, userRole = roles.TENANT_ADMIN, type }) => {
  const { t } = useTranslation()
  const [state, dispatch] = useGlobalState()
  const apartmentPreviewPhoto = item.photos?.find((p) => p.order === 0)?.url ?? APT_PLACEHOLDER
  const isManagementUser = isAdmin(userRole)
  const role = state.currentUser.role
  const { openModal } = useModals()

  return (
    <div key={item.id} className="list-tile" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {/** Галерея */}
      <div className="list-tile__galery">
        <img className="list-tile__galery--photo" loading="lazy" src={apartmentPreviewPhoto} alt={item.title} />
      </div>
      {/** Контент */}
      <div className="list-tile__content">
        {/** Статусы */}
        <div className="list-tile__statuses">
          {/** Activation status */}
          {item.isActive
            ? <Tag color="success">{t('apartments.fields.active-status.active')}</Tag>
            : <Tag color="error">{t('apartments.fields.active-status.not-active')}</Tag>}
          {/** Publish status */}
          {/* {isManagementUser ? (item.isPublished
            ? <Tag color="success">{t('apartments.fields.publish-status.published')}</Tag>
            : <Tag color="error">{t('apartments.fields.publish-status.not-published')}</Tag>) : null} */}
          {/** Доступна ли квартира */}
          {item.isBooked
            ? <Tag color="error">{t('apartments.fields.booking-status.busy')}</Tag>
            : <Tag color="green">{t('apartments.fields.booking-status.available')}</Tag>}
          {/** Ожидаемая дата освобождения квартиры */}
          {item.freeFrom && DateTime.fromISO(item.freeFrom) > DateTime.local()
            ? `${t('apartments.fields.availableFrom')} : ${DateTime.fromISO(item.freeFrom).toFormat('yyyy-LL-dd')}`
            : null}
        </div>
        {/** Действия */}
        <div className="list-tile__actions">
          { actions }
        </div>
        {/** Основная информация */}
        <div className="list-tile__main">
          {/** Заголовок */}

          { type && type === 'rr'  ?
            
            <div 
              type="text" 
              size="small" 
              onClick={() => openModal(ApartmentDetails, { id: item.id })}
            >
              <Typography.Title level={4} className="list-tile__title">{item.title || <Typography.Text type="warning">{t('apartments.fields.title.empty')}</Typography.Text>}</Typography.Title>
            </div> 
         
            :
            <Link to={isTenant(role) ? `/apartments-management/sku/${item.sku}` : `/apartments-management/${item.id}`}>
              <Typography.Title level={4} className="list-tile__title">{item.title || <Typography.Text type="warning">{t('apartments.fields.title.empty')}</Typography.Text>}</Typography.Title>
            </Link>

          }
          
          {/** Адрес */}
          <Typography.Text 
            className="list-tile__location"
            onClick={() => openModal(BuildingDetails, { id: item?.building.id })}
          >
            <LocationOn /> { item.building?.addressDetails?.formatted_address || <Typography.Text type="warning">{t('apartments.fields.address.empty')}</Typography.Text> }
          </Typography.Text>
          {isManagementUser
            ? (
              <div className="list-tile-overview__item">
                {item.building?.user !== null
                  ? userRender({ value: item.building?.user, options: { style: { verticalAlign: 'middle', marginTop: 10, marginLeft: -15 } } })
                  : <Link to={`/buildings-management/${item.building?.id}`}><Tag color="error">{t('buildings.addProfile')}</Tag></Link>}
              </div>
            )
            : null}
          {
            isTenant(userRole) ? (
              // Tenant
              item.request?.reason && item.request?.status !== 6 && item.request?.status > 0
                ? (
                  <Alert
                    style={{ width: '100%', marginTop: '10px' }}
                    message={REQUESTED_APARTMENT_STATUSES[item.request?.status].tenantDescription ?? REQUESTED_APARTMENT_STATUSES[item.request?.status].description}
                    type={REQUESTED_APARTMENT_STATUSES[item.request?.status].alertType}
                    showIcon
                    description={REQUESTED_APARTMENT_STATUSES[item.request?.status].id !== 'TENANT_ACCEPT' ? t('rental-request.components.tenant-apartments-list.reason', item.request) : null}
                  />
                )
                : item.request?.status > 0 ? (
                  <Alert
                    style={{ width: '100%', marginTop: '10px' }}
                    message={REQUESTED_APARTMENT_STATUSES[item.request?.status].description}
                    type={REQUESTED_APARTMENT_STATUSES[item.request?.status].alertType}
                    showIcon
                  />
                ) : null
            ) : (
              // Another roles
              item.request?.reason ? (
                <Alert
                  style={{ width: '100%', marginTop: '10px' }}
                  message={REQUESTED_APARTMENT_STATUSES[item.request?.status].description}
                  type={REQUESTED_APARTMENT_STATUSES[item.request?.status].alertType}
                  showIcon
                  description={`${[2, 4].includes(item.request?.status) ? t('rental-request.components.all-apartments-list.reason') : ''}${item.request?.reason}`}
                />
              ) : item.request ? (
                <Alert
                  style={{ width: '100%', marginTop: '10px' }}
                  message={REQUESTED_APARTMENT_STATUSES[item.request?.status].description}
                  type={REQUESTED_APARTMENT_STATUSES[item.request?.status].alertType}
                  showIcon
                />
              ) : null
            )
          }
        </div>
        {/** Стоимость */}
        <div className="list-tile__cost">
          {/** Стоимость в месяц */}
          <div className="list-tile__cost--month">{cost({ value: ((item.monthPrice + item.utilitiesPrice) * 1.1)  })} / {t('apartments.fields.month')}</div>
          {/** Стоимость коммунтальных */}
          {/* <div className="list-tile__cost--utilities">{item.utilitiesPrice ? <> + {cost({ value: item.utilitiesPrice })} {t('apartments.fields.utilities.value')}</> : <Typography.Text type="warning">{t('apartments.fields.utilities.empty')}</Typography.Text>}</div> */}
        </div>
        {/** Сводка */}
        <div className="list-tile__overview list-tile-overview">
          <div className="list-tile-overview__list">
            {/** Кол-во спален */}
            <div className="list-tile-overview__item">
              <Tooltip title={t('apartments.fields.bedroom')}>
                <span>{ size(item.roomOptions?.bedroom) || <Typography.Text type="warning">???</Typography.Text> }</span>
                <KingBed />
              </Tooltip>
            </div>
            {/** Кол-во ванных */}
            <div className="list-tile-overview__item">
              <Tooltip title={t('apartments.fields.bathroom')}>
                <span>{ size(item.roomOptions?.bathroom) || <Typography.Text type="warning">???</Typography.Text> }</span>
                <Bathtub />
              </Tooltip>
            </div>
            {/** Этаж */}
            <div className="list-tile-overview__item">
              <span>
                { item.floor 
                  ? (item.floor !== 0 ? item.floor+' ' : 'Erdgeschoss')
                  : <Typography.Text type="warning">???</Typography.Text> 
                } 
                {t('apartments.fields.floor')} 
              ({ item?.side ? t(`apartments.fields.side.value.${item?.side}`) : '???'})
              </span>
            </div>
            {/** Площадь для проживания */}
            <div className="list-tile-overview__item">
              <span>{t('apartments.fields.squareMeters')}: { item.squareMeters ? `${item.squareMeters} ㎡` : <Typography.Text type="warning">???</Typography.Text> }</span>
            </div>
            {/** Депозит */}
            <div className="list-tile-overview__item">
              <span>{t('apartments.fields.deposit')}: { cost({ value: item.deposit }) }</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

/**
 * Действия по умолчанию
 */
export const defaultApartmentActions = ({ item, cloneApartmentAction, deleteApartmentAction, context }) => [
  /** Edit apartment */
  <Tooltip key={9990} placement="bottom" title="Edit apartment">
    <Link to={`/apartments-management/${item.id}`} aria-label="Edit apartment" key="edit">
      <Button shape="circle" type="link" size="large" icon={<SettingOutlined />} htmlType="span" />
    </Link>
  </Tooltip>,
  /** Clone apartment */
  cloneApartmentAction ? (
    <Tooltip placement="bottom" title="Duplicate">
      <Popconfirm title="Clone this Apartment?" onConfirm={() => cloneApartmentAction(item)} key="clone">
        <Button shape="circle" type="link" size="large" icon={<CopyOutlined />} />
      </Popconfirm>
    </Tooltip>
  ) : null,
  /** Delete aparment */
  !item.isBooked && deleteApartmentAction && context.dataRefetch ? (
    <Tooltip placement="bottom" title="Remove">
      <Popconfirm title="Delete this Apartment?" onConfirm={() => deleteApartmentAction(item, context.dataRefetch)}>
        <Button shape="circle" type="link" size="large" icon={<DeleteOutlined />} />
      </Popconfirm>
    </Tooltip>
  ) : null,
].filter((action) => !!action)
