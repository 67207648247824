import { Col,Divider,Row } from 'antd'
import { cost } from 'components/ListManager/DefaultTypes'
import React from 'react'
import { useTranslation } from 'react-i18next'

const TotalAmountView = ({ data, profile }) => {
  const { t } = useTranslation()
  return (
    <Row gutter={20} style={{ marginBottom: '0px', lineHeight: '20px', marginTop: '10px', fontSize: '18px' }}>

      <Col xs={14}>
        <h1>{t('apartments.fields.total-amount')}</h1>
      </Col>

      <Col xs={10}>
        <b style={{ }}>{cost({ value: ((data.monthPrice + data.utilitiesPrice) * 1.1) })}</b>
      </Col>

    </Row>

  )
}

export { TotalAmountView }
