import { isAdmin, isLandlord, isTenant, roles } from '_graphql/_enums'
import { TRANSACTION_DELETE_MUTATION,TRANSACTION_UPSERT_MUTATION, TRANSACTIONS_LIST } from '_graphql/transaction'
import AccountCancel from '@2fd/ant-design-icons/lib/AccountCancel'
import AccountCheck from '@2fd/ant-design-icons/lib/AccountCheck'
import AccountClock from '@2fd/ant-design-icons/lib/AccountClock'
import EyeOutlineIcon from '@2fd/ant-design-icons/lib/EyeOutline'
import { CloudServerOutlined,CopyOutlined, CrownOutlined, DeleteOutlined, EyeOutlined, FolderViewOutlined, PlusOutlined, SortAscendingOutlined, SortDescendingOutlined, UserAddOutlined, UserOutlined, UserSwitchOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/react-hooks'
import { Launch } from '@material-ui/icons'
import { Link,navigate } from '@reach/router'
import { Button, Form, Popconfirm, Popover, Tooltip, Typography } from 'antd'
import { useModals } from 'common/ModalsProvider'
import { DatePicker } from 'components/Form/DatePicker'
import { ListManager } from 'components/ListManager'
import { DateTime } from 'luxon'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'state'
import { CountryRender } from 'utils/countries'

import { TransactionDetails } from './TransactionDetails'
import { TransactionForm } from './TransactionForm'

const TransactionsManager = ({refetch: refetchStateProp, fullpage = false, overlay = true, ...props}) => {
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const [form] = Form.useForm()
  const isManagementUser = isAdmin(state.currentUser?.role)
  const { openModal } = useModals()
  const [refetchStateRaw, refetch] = useState()
  const refetchState = useMemo(() => {
    return [refetchStateRaw, refetchStateProp]
  }, [refetchStateRaw, refetchStateProp])


  const [deleteTransaction] = useMutation(TRANSACTION_DELETE_MUTATION)
  const deleteUserTransaction = async (item, callback) => {
    await deleteTransaction({ variables: { id: item.id } })
    callback()
  }


  return (
    <>
      <ListManager
        slug="transactions-manager"
        title={t('transactions.table.title')}
        query={TRANSACTIONS_LIST}
        refetch={refetchState}
        overlay={overlay}
        actions={isManagementUser
          ? [
            <Link 
              to="/transactions-management/create" 
              key="create"
              onClick={(e) => {
                e.preventDefault()
                openModal(TransactionForm, {
                  onClose(id) {
                    refetch(id)
                  }
                })
              }}
            >
              <Button type="primary" icon={<PlusOutlined />} htmlType="span">{t('transactions.table.actions.create')}</Button>
            </Link>,
          ]: []}
        sorting={{
          default: 'id|desc',
          variations: [
            {
              slug: 'id|desc',
              title: t('transactions.table.sorting.idDesc'),
              icon: <SortDescendingOutlined />,
            },
            {
              slug: 'id|asc',
              title: t('transactions.table.sorting.idAsc'),
              icon: <SortAscendingOutlined />,
            },
          ],
        }}
        filter={{
          defaultPreset: 'all',
          presets: [
            {
              title: t('transactions.table.filters.all'),
              slug: 'all',
              value: {},
            },
          ],
          filters: [],
        }}
        table={{
          columns: [
            {
              title: null,
              key: 'sku',
              width: 50,
              options: { na: true },
              render: ({ value, options, item }) => (
                <>
                  <Link
                    to={`/transactions-management/${item.sku}`}
                    key="create"
                    onClick={(e) => {
                      e.preventDefault()
                      openModal(TransactionDetails, {
                        forceQuery: {
                          sku: item.sku,
                        },
                        onClose(id) {
                          refetch(id)
                        }
                      })
                    }}
                  >
                    <Launch fontSize="small" className="icon--mr" />
                  </Link>
                </>
              ),
            },
            {
              title: t('transactions.table.type'),
              key: 'type',
              options: { na: true },
              render: ({ item, context }) => (
                item.type != 1 ? (item.type != 2 ? t('transactions.table.changeOfUser') : t('transactions.form.type.moveOut')) : t('transactions.form.type.moveIn')
              ),
            },
            {
              title: t('transactions.table.status'),
              key: 'status',
              options: { na: true },
              render: ({ item }) => {
                if (item.status === 'accepted') {
                  return <Typography.Text type="success">{t('transactions.table.accepted')}</Typography.Text>
                }

                if (item.status === 'completed') {
                  return <Typography.Text>{t('transactions.table.completed')}</Typography.Text>
                }

                if (item.status === 'canceled') {
                  return <Typography.Text type="danger">{t('transactions.table.canceled')}</Typography.Text>
                }

                if (item.status === 'handover') {
                  return <Typography.Text type="success">{t('transactions.table.handover')}</Typography.Text>
                }

                return <Typography.Text type="warning">{t('transactions.table.waiting')}</Typography.Text>
              },
            },
            {
              title: t('transactions.table.user'),
              key: 'moveInUser',
              render: 'user',
              middleware: () => !isLandlord(state.currentUser?.role),
            },
            {
              title: t('transactions.table.moveout'),
              key: 'moveOutUser',
              render: 'user',
              middleware: () => !isLandlord(state.currentUser?.role),
            },

            {
              
              title: t('transactions.table.date'),
              key: 'createdAt',
              options: { na: true },
              width: 100,
              render: ({ item }) => {
                // Completed

                if (item.status === 'completed') {
                  return DateTime.fromISO(item.completedAt).toFormat('yyyy-LL-dd')
                }
                if (item.type === 3 ) {
                  return `${DateTime.fromISO(item.moveInDate).toFormat('yyyy-LL-dd')}`
                }

                if (item.type === 1 ) {
                  return `${DateTime.fromISO(item.moveInDate).toFormat('yyyy-LL-dd')}`
                }
                if (item.type === 2 ) {
                  return `${DateTime.fromISO(item.moveOutDate).toFormat('yyyy-LL-dd')}`
                }
              },
            },
           
            {
              key: 'actions',
              title: t('transactions.table.actions.title'),
              width: 100,
              render: ({ item, context }) => (
                <>
                 
                  {/** Remove */}
                  {!item.isBooked ? (
                    <Tooltip placement="bottom" title={t('transactions.table.actions.archive')}>
                      <Popconfirm title={t('transactions.table.actions.archiveConfirm')} onConfirm={() => deleteUserTransaction(item, context.dataRefetch)}>
                        <Button shape="circle" type="link" icon={<DeleteOutlined />} />
                      </Popconfirm>
                    </Tooltip> ) : null }
                </>
              ),
            },
          ],

        }}
        
        {...props}
      />
    </>
  )
}

export { TransactionDetails } from './TransactionDetails'
export { TransactionForm } from './TransactionForm'
export { TransactionsManager }
