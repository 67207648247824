import { MAKE_CONTRACT_SERVICE } from '_graphql'
import {
  CONTRACT_STATUS,
  detectCurrentContractStatus,
  isAdmin,
  isLandlord,
  isTenant,
  ROLE_GROUPS,
  roles,
  USER_ROLES,
} from '_graphql/_enums'
import { RENTAL_CONTRACTS_LIST } from '_graphql/rentalContract/queries'
import EyeOutline from '@2fd/ant-design-icons/lib/EyeOutline'
import {
  BankOutlined,
  CopyOutlined,
  CrownOutlined,
  DeleteOutlined,
  FolderViewOutlined,
  PlusOutlined,
  ReloadOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  UserAddOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import { ContactMail } from '@material-ui/icons'
import { Link, navigate } from '@reach/router'
import { Button,message,Tag, Tooltip } from 'antd'
import { useModals } from 'common/ModalsProvider'
import { ListManager } from 'components/ListManager'
import { cost, string } from 'components/ListManager/DefaultTypes'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInterval } from 'react-use'
import { useGlobalState } from 'state'
import { db } from 'utils/dexie'

import { ContractForm } from './ContractForm'
import { RentalContractDetails } from './RentalContractDetails'

const useFilters = () => {
  const [state] = useGlobalState()
  // const isManagementUser = isAdmin(state.currentUser?.role)
  // const isTenantUser = isTenant(state.currentUser?.role)
  // const isLandlordUser = isLandlord(state.currentUser?.role)

  const filters = {
    all: {},
    actionRequired: {
      statuses: [
        CONTRACT_STATUS.new.id,
        CONTRACT_STATUS['need-reaction'].id,
      ],
    }
  }

  return filters
}

const RentalContracts = (props) => {
  const { t } = useTranslation()
  const [state] = useGlobalState()
  const isManagementUser = isAdmin(state.currentUser?.role)
  const isLandlordUser = isLandlord(state.currentUser?.role)
  const isTenantUser = isTenant(state.currentUser?.role)
  const { openModal } = useModals()
  const filters = useFilters()

  const [runFix, { data: result }] = useLazyQuery(MAKE_CONTRACT_SERVICE, { fetchPolicy: 'no-cache' })

  

  const makeServices = () => {
    runFix()
    message.success('Service check done')
  }

  return (
    <>
      <ListManager
        slug="contracts-manager"
        title={t('contract.manager-title')}
        query={RENTAL_CONTRACTS_LIST}
        queryParams={{}}
        pagination = {{pageSize: 20 }}
        lastSeen="rentalContractsLastSeen"
        actions={
          isManagementUser
            ? [
              <Link 
                to="/rental-contracts-management/create" 
                key="create"
                onClick={(e) => {
                  e.preventDefault()
                  openModal(ContractForm, {
                    onClose(sku) {
                      navigate(`/rental-contracts-management/${sku || ''}`)
                    }
                  })
                }}
              >
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  htmlType="span"
                >
                  {t('contract.fields.create')}
                </Button>
              </Link>,
              // <Button
              //   key="make"
              //   onClick={() => makeServices()}
              // >make</Button>
            ]
            : []
        }
        sorting={{
          default: 'id|desc',
          variations: [
            {
              slug: 'id|desc',
              title: t('contract.sorting.id|desc'),
              icon: <SortDescendingOutlined />,
            },
            {
              slug: 'id|asc',
              title: t('contract.sorting.id|asc'),
              icon: <SortAscendingOutlined />,
            },
            {
              slug: 'tenantId|desc',
              title: t('contract.sorting.tenantId|desc'),
              icon: <UserSwitchOutlined />,
            },
            {
              slug: 'salesId|desc',
              title: t('contract.sorting.salesId|desc'),
              icon: <UserSwitchOutlined />,
            },
            {
              slug: 'landlordId|desc',
              title: t('contract.sorting.landlordId|desc'),
              icon: <CrownOutlined />,
            },
          ],
        }}
        filter={{
          defaultPreset: 'all',
          presets: [
            {
              slug: 'all',
              title: t('contract.filters.all'),
              value: {},
            },
            {
              slug: 'action-required',
              title: t('contract.filters.action-required'),
              value: {
                statuses: [
                  CONTRACT_STATUS.new.id,
                  CONTRACT_STATUS['need-reaction'].id,
                ],
              },
            },
            ...Object.values(CONTRACT_STATUS)
              .filter(
                (status) => status.id != CONTRACT_STATUS['need-reaction'].id
              )
              .map((status) => ({
                title: status.title,
                slug: status.id,
                value: { statuses: [status.id] },
              })),
          ],
          filters: [
            // {
            //   title: 'Contract status',
            //   icon: <Gavel className="icon--mr" fontSize="small" />,
            //   slug: 'statuses',
            //   type: 'tags',
            //   options: {
            //     values: Object.values(CONTRACT_STATUS).map((status) => ({ title: status.title, key: status.id, color: status.color, value: { status: status.id } })),
            //   },
            // },
            {
              slug: 'tenants',
              title: t('contract.filters.tenants'),
              icon: <UserOutlined />,
              middleware: isManagementUser,
              type: 'dynamic',
              options: {
                preset: 'users',
                filter: { role: [USER_ROLES['tentant-admin'].key] },
              },
            },
            {
              slug: 'sales',
              title: t('contract.filters.sales'),
              icon: <BankOutlined />,
              middleware: isManagementUser,
              type: 'dynamic',
              options: {
                preset: 'users',
                filter: {
                  role: Object.values(USER_ROLES)
                    .filter((role) => ROLE_GROUPS.B2B.includes(role.id))
                    .map((role) => role.key),
                },
              },
            },
            {
              slug: 'landlords',
              title: t('contract.filters.landlords'),
              icon: <CrownOutlined />,
              middleware: isManagementUser,
              type: 'dynamic',
              options: {
                preset: 'users',
                filter: { role: [USER_ROLES['landlord-owner'].key] },
              },
            },
          ],
        }}
        table={{
          columns: [
            {
              key: 'sku',
              title: t('contract.fields.sku'),
              width: 120,
              options: { na: true },
              render: ({ options, item }) => (
                <div style={{ display: 'flex' }}>
                  <Link to={`/rental-contracts-management/${item.sku}`}>
                    { item.sku.substr(0, 8) }
                  </Link>

                  {/* Open in modal */}
                  <Button 
                    type="text" 
                    size="small" 
                    onClick={() => openModal(RentalContractDetails, { sku: item.sku })}
                  >
                    <EyeOutline />
                  </Button>
                </div>
              ),
            },
            {
              key: 'code',
              title: t('contract.fields.code'),
              width: 100,
              options: { na: true },
              render: ({ options, item }) => {
                let link = '#'

                if (isTenantUser && item?.apartment?.sku) {
                  link = `/apartments-management/sku/${item?.apartment?.sku}`
                }

                if (!isTenantUser && item.apartmentId) {
                  link = `/apartments-management/${item.apartmentId}`
                }

                return (
                  <Link to={link}>
                    {string({ value: item?.apartment?.code, options })}
                  </Link>
                )
              },
            },
            {
              key: 'status',
              title: t('contract.fields.status'),
              render: ({ item }) => {
                const statuses = detectCurrentContractStatus(item, state.currentUser)

                if (statuses.length === 0) {
                  return '-'
                }

                return (
                  <>
                    {statuses.map(status => (
                      <Tag key={status.id} color={status.secondColor || status.color}>
                        {status.title}
                        {status.subtitle ? ` (${status.subtitle})` : null}
                      </Tag>
                    ))}
                  </>
                )
              },
            },
            {
              key: 'tenant',
              title: t('contract.fields.tenant'),
              options: { userRole: state.currentUser?.role },
              render: 'user',
              middleware: isManagementUser || isLandlordUser,
            },
            {
              key: 'landlord',
              title: t('contract.fields.landlord'),
              options: { userRole: state.currentUser?.role },
              render: 'user',
              middleware: !isLandlordUser,
            },
            {
              key: 'dates',
              title: t('contract.fields.dates'),
              render: ({ item }) => {
                const dateFrom = item?.dateFrom
                  ? DateTime.fromISO(item?.dateFrom)
                  : null
                const dateTill = item?.dateTill
                  ? DateTime.fromISO(item?.dateTill)
                  : null
                const diff =
                  dateFrom && dateTill
                    ? dateTill.diff(dateFrom, ['months', 'days'])
                    : null

                return `${dateFrom ? dateFrom.toFormat('yyyy-LL-dd') : ''}${
                  dateFrom && dateTill ? ' — ' : ''
                }${dateTill ? dateTill.toFormat('yyyy-LL-dd') : ''}${
                  diff
                    ? ` (${[
                      diff.months ? `${Math.ceil(diff.months)}mo.` : '',
                      diff.days ? `${Math.ceil(diff.days)}d.` : '',
                    ]
                      .filter((item) => !!item)
                      .join(' ')})`
                    : ''
                }`
              },
            },
            {
              title: t('contract.fields.services'),
              key: 'services',
              width: 100,
              render: ({ item }) => {
                let total = 0
                
                item.services.map((service) => {
                  // console.log(service)
                  if (service) {
                   
                    total += service.grossPrice ?? 0
                  
                  } 
                 
                })
                
                return (
                  <span role="button" tabIndex="0" aria-label={t('contract.actions.apartment-status.toggle')} className="cursor-pointer" style={{ display: 'block', minWidth: 102 }}>
                    {cost({ value: total})}
                  </span>
                )
              },
            },
            {
              key: 'rentalRequestId',
              title: t('contract.fields.rentalRequestId'),
              options: { na: true },
              render: ({ options, item }) => (
                <Tooltip
                  title={
                    item.rentalRequest?.id > 0
                      ? t('contract.go-to-rental-request')
                      : t('contract.no-rental-request')
                  }
                >
                  <Link
                    to={`${
                      isLandlordUser ? '/landlord' : ''
                    }/rental-requests-management/${item?.rentalRequest?.sku}`}
                  >
                    { item?.rentalRequest?.sku.substr(0, 8) }
                  </Link>
                </Tooltip>
              ),
            },
          ],
        }}
        {...props}
      />
    </>
  )
}

export function useRentalContractsCounter (filter = 'all') {
  const filters = useFilters()
  const { data, loading, refetch } = useQuery(RENTAL_CONTRACTS_LIST, { variables: { page: 1, pageSize: 1000, filter: JSON.stringify(filters[filter]) }, fetchPolicy: 'no-cache' })
  const { list } = data?.response ?? { list: [] }
  const [lastSeen, setLastSeen] = useState([])

  useInterval(async () => {
    setLastSeen(await db.rentalContractsLastSeen.where('id').anyOf(...list.map(item => item.id)).toArray())
  }, 3500)

  const total = list.filter(itemLeft => (itemLeft.updatedAt !== lastSeen.find(itemRight => itemRight.id === itemLeft.id)?.updatedAt)).length || 0

  return { value: total, loading: loading || lastSeen.loading, refetch }
}

export { RentalContracts }
export { ContractForm } from './ContractForm'
export { RentalContractDetails } from './RentalContractDetails'
